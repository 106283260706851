import React, { useState, useRef, useEffect } from 'react';
import { Spin, Button } from 'antd';
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import agenda from "../../assets/portada-agenda-1.png";

import '../pdf-worker'; // Importa el worker configurado

const PDFViewer = (props) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [scale, setScale] = useState(1);
    const containerRef = useRef(null);

    useEffect(() => {
        if (true) {
            adjustScale();
            window.addEventListener('resize', adjustScale);
        }
        return () => {
            window.removeEventListener('resize', adjustScale);
        };
    }, []);

    const adjustScale = () => {
        if (containerRef.current) {
            const { width, height } = containerRef.current.getBoundingClientRect();
            const desiredWidth = width - 20; // Deja un margen pequeño
            const desiredHeight = height - 20; // Deja un margen pequeño

            // Ajusta el PDF proporcionalmente al ancho o al alto disponible
            const calculatedScale = Math.min(desiredWidth / 595, desiredHeight / 842); // 595x842 es el tamaño A4 en puntos
            setScale(calculatedScale);
        }
    };

    const renderExtraPage = () => (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                border: '1px solid #ccc',
                margin: '16px 0',
                padding: '16px',
                background: '#f9f9f9',
                textAlign: 'center',
            }}
        >
            <div className="mt-4 text-center banner-agenda" style={{ backgroundColor: "#FA824C" }}>

                <img
                    src={agenda}
                    alt="Agenda LittleCoders.dev"
                    style={{ width: "50%", borderRadius: "15px", transform: "rotate(-22deg)" }}
                />
            </div>
            <Button
                type="primary"
                size="large"
                className="my-4"
                style={{
                    backgroundColor: "#5B88FA",
                    border: "none",
                    padding: "10px 20px",
                    borderRadius: "25px",
                    fontSize: "1rem",
                    width: "auto"
                }}
                onClick={() => window.open('https://littlecoders.dev/redirect?url=https://www.amazon.es/dp/B0DNTNG591', '_blank')}
            >
                Compra en Amazon tu agenda littlecoders.dev
            </Button>
        </div>
    );

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    return (
        <div
            ref={containerRef}
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden',
            }}
        >
            <div className='text-center'>
                <button
                    onClick={() => setPageNumber((prev) => Math.max(prev - 1, 1))}
                    disabled={pageNumber === 1}
                    style={{
                        color: "#5B88FA",
                        border: "none",
                        padding: "10px 20px",
                        borderRadius: "25px",
                        fontSize: "1rem",
                    }}
                >
                    <FaAngleLeft />
                </button>
                <button
                    //onClick={() => setPageNumber((prev) => Math.min(prev + 1, numPages))}
                    onClick={() =>
                        setPageNumber((prev) =>
                            prev < numPages + 1 ? prev + 1 : prev
                        )
                    }
                    disabled={pageNumber === numPages + 1}
                    style={{
                        color: "#5B88FA",
                        border: "none",
                        padding: "10px 20px",
                        borderRadius: "25px",
                        fontSize: "1rem",
                    }}
                >
                    <FaAngleRight />
                </button>

                <Document
                    file={props.url}
                    onLoadSuccess={onDocumentLoadSuccess}
                    loading={<Spin size="large" />}
                    renderMode="canvas" // Usa "canvas" para rendimiento
                    options={{ cMapUrl: 'cmaps/', cMapPacked: true }}
                    className={"mt-4"}
                >
                    {pageNumber <= numPages &&
                        <Page pageNumber={pageNumber}
                            scale={0.925} // Aplica la escala dinámica
                            renderAnnotationLayer={false} // Mejora rendimiento
                            renderTextLayer={false} // Mejora rendimiento
                        />

                    }
                    {/* Agrega la página adicional */}
                    {pageNumber === numPages + 1 && renderExtraPage()}
                </Document>
            </div>
            {/* 
            <div>
                <div style={{ textAlign: 'center', marginTop: 4 }}>
                    <Pagination
                        current={pageNumber}
                        total={numPages * 10}
                        onChange={(page) => setPageNumber(page)}
                        simple
                    />
                </div>
                <p>
                Página {pageNumber} de {numPages}
                </p>
            </div>
            */}
        </div>
    );
};

export default PDFViewer;
