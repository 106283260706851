import React from "react";
import { hydrateRoot, createRoot } from "react-dom/client";
import reportWebVitals from "./reportWebVitals";

import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'antd-mobile/es/global';

import App from "./App";
import RoutesCode from "./routes";
import "./constants/polyfills";

//import "./index.css";

const container = document.getElementById("root");

const app = (
  <React.StrictMode>
      <RoutesCode />
      <App />
  </React.StrictMode>
);

if (container.hasChildNodes()) {
  hydrateRoot(container, app);
} else {
  createRoot(container).render(app);
}

reportWebVitals();
