export const examplesData = [
  {
    id: 1,
    title: "hola mundo",
    key: "1_hola-mundo",
    url: "https://littlecoders.dev/playground/1_hola-mundo",
  },
  {
    id: 2,
    title: "variables",
    key: "2_variables",
    url: "https://littlecoders.dev/playground/2_variables",
  },
  {
    id: 3,
    title: "operaciones básicas",
    key: "3_operaciones-basicas",
    url: "https://littlecoders.dev/playground/3_operaciones-basicas",
  },
  {
    id: 4,
    title: "entrada del usuario",
    key: "4_entrada-del-usuario",
    url: "https://littlecoders.dev/playground/4_entrada-del-usuario",
  },
  {
    id: 5,
    title: "conversión de tipos",
    key: "5_conversion-de-tipos",
    url: "https://littlecoders.dev/playground/5_conversion-de-tipos",
  },
  {
    id: 6,
    title: "condicional if",
    key: "6_condicional-if",
    url: "https://littlecoders.dev/playground/6_condicional-if",
  },
  {
    id: 7,
    title: "condicional if else",
    key: "7_condicional-if-else",
    url: "https://littlecoders.dev/playground/7_condicional-if-else",
  },
  {
    id: 8,
    title: "bucles while",
    key: "8_bucles-while",
    url: "https://littlecoders.dev/playground/8_bucles-while",
  },
  {
    id: 9,
    title: "bucles for",
    key: "9_bucles-for",
    url: "https://littlecoders.dev/playground/9_bucles-for",
  },
  {
    id: 10,
    title: "listas y tuplas",
    key: "10_listas_tuplas",
    url: "https://littlecoders.dev/playground/10_listas_tuplas",
  },
  {
    id: 11,
    title: "recorrer listas",
    key: "11_recorrer-listas",
    url: "https://littlecoders.dev/playground/11_recorrer-listas",
  },
  {
    id: 12,
    title: "funciones",
    key: "12_funciones",
    url: "https://littlecoders.dev/playground/12_funciones",
  },
  {
    id: 13,
    title: "funciones con parámetros",
    key: "13_funciones-con-parametros",
    url: "https://littlecoders.dev/playground/13_funciones-con-parametros",
  },
  {
    id: 14,
    title: "retorno de funciones",
    key: "14_retorno-de-funciones",
    url: "https://littlecoders.dev/playground/14_retorno-de-funciones",
  },
  {
    id: 15,
    title: "módulo random",
    key: "15_modulo-random",
    url: "https://littlecoders.dev/playground/15_modulo-random",
  },
  {
    id: 16,
    title: "manejo de cadenas",
    key: "16_manejo-de-cadenas",
    url: "https://littlecoders.dev/playground/16_manejo-de-cadenas",
  },
  {
    id: 17,
    title: "slicing de cadenas",
    key: "17_slicing-de-cadenas",
    url: "https://littlecoders.dev/playground/17_slicing-de-cadenas",
  },
  {
    id: 18,
    title: "diccionarios",
    key: "18_diccionarios",
    url: "https://littlecoders.dev/playground/18_diccionarios",
  },
  {
    id: 19,
    title: "manejo de archivos lectura",
    key: "19_manejo-de-archivos-lectura",
    url: "https://littlecoders.dev/playground/19_manejo-de-archivos-lectura",
  },
  {
    id: 20,
    title: "manejo de archivos escritura",
    key: "20_manejo-de-archivos-escritura",
    url: "https://littlecoders.dev/playground/20_manejo-de-archivos-escritura",
  },
  {
    id: 21,
    title: "manejo de errores",
    key: "21_manejo-de-errores",
    url: "https://littlecoders.dev/playground/21_manejo-de-errores",
  },
  {
    id: 22,
    title: "clases y objetos",
    key: "22_clases-y-objetos",
    url: "https://littlecoders.dev/playground/22_clases-y-objetos",
  },
  {
    id: 23,
    title: "módulos",
    key: "23_modulos",
    url: "https://littlecoders.dev/playground/23_modulos",
  },
  {
    id: 24,
    title: "list comprehensions",
    key: "24_list-comprehensions",
    url: "https://littlecoders.dev/playground/24_list-comprehensions",
  },
  {
    id: 25,
    title: "funciones lambda",
    key: "25_funciones-lambda",
    url: "https://littlecoders.dev/playground/25_funciones-lambda",
  },
  {
    id: 26,
    title: "módulo time",
    key: "26_modulo-time",
    url: "https://littlecoders.dev/playground/26_modulo-time",
  },
  {
    id: 27,
    title: "enumerate",
    key: "27_enumerate",
    url: "https://littlecoders.dev/playground/27_enumerate",
  },
  {
    id: 28,
    title: "módulo zip",
    key: "28_modulo-zip",
    url: "https://littlecoders.dev/playground/28_modulo-zip",
  },
  {
    id: 29,
    title: "conjuntos sets",
    key: "29_conjuntos-sets",
    url: "https://littlecoders.dev/playground/29_conjuntos-sets",
  },
  {
    id: 30,
    title: "operadores lógicos",
    key: "30_operadores-logicos",
    url: "https://littlecoders.dev/playground/30_operadores-logicos",
  },
  {
    id: 31,
    title: "lectura de JSON",
    key: "31_lectura-de-json",
    url: "https://littlecoders.dev/playground/31_lectura-de-json",
  },
  {
    id: 32,
    title: "escribir JSON",
    key: "32_escribir-json",
    url: "https://littlecoders.dev/playground/32_escribir-json",
  },
  {
    id: 33,
    title: "recursión",
    key: "33_recursion",
    url: "https://littlecoders.dev/playground/33_recursion",
  },
  {
    id: 34,
    title: "generadores",
    key: "34_generadores",
    url: "https://littlecoders.dev/playground/34_generadores",
  },
  {
    id: 35,
    title: "decoradores",
    key: "35_decoradores",
    url: "https://littlecoders.dev/playground/35_decoradores",
  },
  {
    id: 36,
    title: "módulo OS",
    key: "36_modulo-os",
    url: "https://littlecoders.dev/playground/36_modulo-os",
  },
  {
    id: 37,
    title: "expresiones regulares",
    key: "37_expresiones-regulares",
    url: "https://littlecoders.dev/playground/37_expresiones-regulares",
  },
  {
    id: 38,
    title: "herencia de objetos",
    key: "38_herencia-de-objetos",
    url: "https://littlecoders.dev/playground/38_herencia-de-objetos",
  },
  {
    id: 39,
    title: "pilas y colas",
    key: "39_pilas-y-colas",
    url: "https://littlecoders.dev/playground/39_pilas-y-colas",
  },
  {
    id: 40,
    title: "comparar dos números",
    key: "40_comparar-dos-numeros",
    url: "https://littlecoders.dev/playground/40_comparar-dos-numeros",
  },
  {
    id: 41,
    title: "contar vocales en una frase",
    key: "41_contar-vocales-en-una-frase",
    url: "https://littlecoders.dev/playground/41_contar-vocales-en-una-frase",
  },
  {
    id: 42,
    title: "sumar los números de una lista",
    key: "42_sumar-los-numeros-de-una-lista",
    url: "https://littlecoders.dev/playground/42_sumar-los-numeros-de-una-lista",
  },
  {
    id: 43,
    title: "tablas de multiplicar",
    key: "43_tablas-de-multiplicar",
    url: "https://littlecoders.dev/playground/43_tablas-de-multiplicar",
  },
  {
    id: 44,
    title: "proyecto juego adivina el número",
    key: "44_proyecto-juego-adivina-el-numero",
    url: "https://littlecoders.dev/playground/44_proyecto-juego-adivina-el-numero",
  },
];

export const figuresData = [
  {
    id: 1,
    title: "Ada Lovelace",
    key: "Ada-Lovelace",
    url: "https://littlecoders.dev/figures/Ada_Lovelace"
  },
  {
    id: 2,
    title: "Alan Turing",
    key: "Alan-Turing",
    url: "https://littlecoders.dev/figures/Alan_Turing"
  },
  {
    id: 3,
    title: "Leonardo Torres-Quevedo",
    key: "Leonardo-Torres-Quevedo",
    url: "https://littlecoders.dev/figures/Leonardo_Torres-Quevedo"
  },
  {
    id: 4,
    title: "Grace Hopper",
    key: "Grace-Hopper",
    url: "https://littlecoders.dev/figures/Grace_Hopper"
  },
  {
    id: 5,
    title: "John McCarthy",
    key: "John-McCarthy",
    url: "https://littlecoders.dev/figures/John_McCarthy"
  },
  {
    id: 6,
    title: "Claude Shannon",
    key: "Claude-Shannon",
    url: "https://littlecoders.dev/figures/Claude_Shannon"
  },
  {
    id: 7,
    title: "Alan Kay",
    key: "Alan-Kay",
    url: "https://littlecoders.dev/figures/Alan_Kay"
  },
  {
    id: 8,
    title: "Vinton Cerf",
    key: "Vinton-Cerf",
    url: "https://littlecoders.dev/figures/Vinton_Cerf"
  },
  {
    id: 9,
    title: "Fernando J. Corbató",
    key: "Fernando-J.-Corbató",
    url: "https://littlecoders.dev/figures/Fernando_J._Corbató"
  },
  {
    id: 10,
    title: "Jordi Sabater Pi",
    key: "Jordi-Sabater-Pi",
    url: "https://littlecoders.dev/figures/Jordi_Sabater_Pi"
  },
  {
    id: 11,
    title: "Martin Fowler",
    key: "Martin-Fowler",
    url: "https://littlecoders.dev/figures/Martin_Fowler"
  },
  {
    id: 12,
    title: "James Gosling",
    key: "James-Gosling",
    url: "https://littlecoders.dev/figures/James_Gosling"
  },
  {
    id: 13,
    title: "Niklaus Wirth",
    key: "Niklaus-Wirth",
    url: "https://littlecoders.dev/figures/Niklaus_Wirth"
  },
  {
    id: 14,
    title: "Guido van Rossum",
    key: "Guido-van-Rossum",
    url: "https://littlecoders.dev/figures/Guido_van_Rossum"
  },
  {
    id: 15,
    title: "Dennis Ritchie",
    key: "Dennis-Ritchie",
    url: "https://littlecoders.dev/figures/Dennis_Ritchie"
  },
  {
    id: 16,
    title: "Donald Knuth",
    key: "Donald-Knuth",
    url: "https://littlecoders.dev/figures/Donald_Knuth"
  },
  {
    id: 17,
    title: "Charles Babbage",
    key: "Charles-Babbage",
    url: "https://littlecoders.dev/figures/Charles_Babbage"
  },
  {
    id: 18,
    title: "Linus Torvalds",
    key: "Linus-Torvalds",
    url: "https://littlecoders.dev/figures/Linus_Torvalds"
  },
  {
    id: 19,
    title: "Hedy Lamarr",
    key: "Hedy-Lamarr",
    url: "https://littlecoders.dev/figures/Hedy_Lamarr"
  },
  {
    id: 20,
    title: "John von Neumann",
    key: "John-von-Neumann",
    url: "https://littlecoders.dev/figures/John_von_Neumann"
  },
  {
    id: 21,
    title: "Gordon Moore",
    key: "Gordon-Moore",
    url: "https://littlecoders.dev/figures/Gordon_Moore"
  },
  {
    id: 22,
    title: "Tim Berners-Lee",
    key: "Tim-Berners-Lee",
    url: "https://littlecoders.dev/figures/Tim_Berners-Lee"
  },
  {
    id: 23,
    title: "Konrad Zuse",
    key: "Konrad-Zuse",
    url: "https://littlecoders.dev/figures/Konrad_Zuse"
  }
];
