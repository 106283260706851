// src/PythonConsole.js
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { Button, List as MList } from 'antd-mobile';
import { Card, Spinner } from 'react-bootstrap';
import { List, Drawer } from 'antd';
import { FaAngleRight } from 'react-icons/fa';

import Header from './visual/header';
import Footer from './visual/footer';
import { figuresData } from '../data/examples';
import { getWeekNumber } from '../services/utils';


function FigureCard() {
  const { filename } = useParams();
  const [bio, setBio] = useState('');
  const [bioImg, setBioImg] = useState('');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  let navigate = useNavigate();
  const weekIndex = getWeekNumber(new Date());

  useEffect(() => {

    console.log("week", getWeekNumber(new Date()))
  }, []);

  useEffect(() => {
    if (filename) {
      const fetchBio = async () => {
        try {
          //TODO: resolverlo mejor en un subdir, pero no funciona por permisos o CORS
          const uri = `/${filename.replace("-", "_")}.json`;
          const response = await fetch(uri);
          if (!response.ok) {
            throw new Error('No se pudo cargar el código');
          }
          const data = await response.json();
          setBio(data.bio);
          setBioImg(data.photo)
        } catch (error) {
          console.error('Error al cargar el código:', error);
          setBio('# Error al cargar el código. Prueba con tu propio codigo y aprende.');
        } finally {
          setLoading(false)
        }
      };
      fetchBio();
    }
  }, [filename]);


  return (
    <>
      <Header />
      <div className="container-fluid">
        <div className="row">
          {/* Botón Hamburguesa para móvil */}
          <div className="d-md-none py-2">
            <Button type="primary" onClick={() => setIsDrawerOpen(true)}>
              Personajes < FaAngleRight />
            </Button>
          </div>

          {/* Sidebar desktop */}
          <nav className="col-md-4 col-lg-3 d-none d-md-block bg-light sidebar" style={{ height: "75vh" }}>
            <div className="pt-3">
              <h5>Personajes</h5>
              <List
                itemLayout="horizontal"
                dataSource={figuresData}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      title={
                        item.id > weekIndex ? (
                          <span className='disabled'>{item.id}. {item.title}</span>
                        ) : (
                          <Link to={"/figures/" + item.key}>{item.id}. {item.title}</Link>
                        )
                      }
                    //description={`ID: ${item.id}`}
                    />
                  </List.Item>
                )}
              />
            </div>
          </nav>

          {/* Drawer para el Sidebar en móvil */}
          <Drawer
            title="Ejemplos"
            placement="left"
            onClose={() => setIsDrawerOpen(false)}
            open={isDrawerOpen}
            bodyStyle={{ padding: "10px", width: "100vw" }}
          >

            <MList>
              {figuresData.map(item => (
                <List.Item key={item.id} prefix={<i className="fab fa-python"></i>}>
                  {
                    item.id > weekIndex ? (
                      <span className='disabled'>{item.id}. {item.title}</span>
                    ) : (
                      <Link
                        to={"/figures/" + item.key}
                        onClick={() => {
                          setIsDrawerOpen(false);
                        }}
                      >
                        {item.id}. {item.title}
                      </Link>
                    )
                  }
                </List.Item>
              ))}
            </MList>
          </Drawer>

          {/* Main Content */}
          <main className="col-md-8 ms-sm-auto col-lg-9 px-md-4">
            <div className="content mt-2">
              <div className="container- my-3" style={{ height: "65vh" }}>
                <Card className="mt-2"

                >
                  <Card.Img variant="top" src={bioImg} style={{width: "50vw"}} />

                  <Card.Header>
                    <div className="float-left mt-2" style={{ display: "inline-block" }}>
                      <h5>
                        {filename ? filename : "littlecoders.dev"}
                      </h5>
                    </div>

                  </Card.Header>
                  <Card.Body>
                    {loading ? (
                      <div className="text-center my-5">
                        <Spinner animation="border" />
                      </div>
                    ) : (
                      <>
                        {bio}
                      </>
                    )}
                  </Card.Body>
                </Card>
              </div>
            </div>
          </main>
        </div>
      </div>

      <Footer />
    </>

  );
}

export default FigureCard;
