import { BACK_URI, BASE_URI} from "../constants/const"

export const getChunkByKey = async (key) => {
  const uri = `${BASE_URI}proxy.php?url=${BACK_URI}chunks/${key}`;
  try {
    const response = await fetch(uri);
    if (!response.ok) {
      throw new Error("No se pudo cargar el chunk");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

// no se usa 
export const fetchCode = async (filename) => {
  try {
    const response = await fetch(`/${filename}.json`);
    if (!response.ok) {
      throw new Error("No se pudo cargar el código");
    }
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

/**
 * Calcula el número de semana a partir de una fecha base
 * @param {Date} targetDate - Fecha para la cual queremos calcular el número de semana
 * @param {Date} startDate - Fecha de inicio desde la cual empezar a contar (por defecto 2024-09-02)
 * @returns {number} Número de semana (empezando desde 1)
 */
export function getWeekNumber(targetDate, startDate = new Date('2024-09-02')) {
  // Validación de parámetros
  if (!(targetDate instanceof Date) || isNaN(targetDate)) {
      throw new Error('Se requiere una fecha válida');
  }

  // Normalizar las fechas al inicio del día para evitar problemas con las horas
  const normalizedTarget = new Date(targetDate.setHours(0, 0, 0, 0));
  const normalizedStart = new Date(startDate.setHours(0, 0, 0, 0));

  // Calcular la diferencia en días
  const diffTime = normalizedTarget - normalizedStart;
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

  // Calcular el número de semana (sumamos 1 porque la primera semana es 1, no 0)
  const weekNumber = Math.floor(diffDays / 7) + 1;

  return weekNumber > 0 ? weekNumber : 0;
}