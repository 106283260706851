import React, { useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import logo from "../assets/logo.webp";

function Home(props) {
  //let navigate = useNavigate();

  useEffect(() => {
    document.title = "littlecoders.dev";
  }, []);

  return (
    <>
      <Helmet>
        <link rel="canonical" href={"/"} />
        <meta name="description" content="" />
      </Helmet>
      <div className="container text-center my-5">
        <img src={logo} alt="LittleCoders.dev Logo" style={{ width: "200px" }} />
        <h1 className="my-4">{props.message}</h1>

        <div className="jumbotron my-4">
          <div className="col-md-8 col-xs-11 offset-md-2"></div>
        </div>
      </div>
    </>
  );
}

export default Home;
