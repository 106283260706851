import logo from "../../assets/logo.png";


function Header() {
    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-white fixed-top- shadow-sm">
            <div className="container">
                <a className="navbar-brand-d-flex align-items-center" href="/">
                    <img src={logo} alt="Littlecoders.dev logo" className="rounded-circle- me-2" style={{ width: 'auto', height: '40px' }} />
                </a>
            </div>
        </nav>
    );
}
export default Header;