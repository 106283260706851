import React, { useEffect } from "react";

import logo from "../assets/logo.webp";


function Contact() {

    useEffect(() => {
        document.title = "littlecoders.dev. Contacto";
    }, []);

    return (
        <div className="col-xs-12">
            <div className="container">
                <br />
                <div className="jumbotron text-center">
                    <a href="/">
                        <img
                            src={logo}
                            className="img img-responsive col-4"
                            alt="littlecoders.dev logo"
                        />
                    </a>
                    <div className="lead p-4 mx-4">
                        <h4 className="p-3">
                            Contacta con nosotros para cualquier duda o sugerencia
                        </h4>
                    </div>
                    <div className="col-xs-12 text-center">
                        <h5>
                            <a href="mailto:contact@littlecoders.dev?subject=contacto" className="contact">
                                contact@littlecoders.dev
                            </a>
                        </h5>
                    </div>
                </div>
                <br />
            </div>
        </div>
    );
}

export default Contact;
