import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Skeleton } from "antd-mobile";
import { Helmet } from "react-helmet";
import { getChunkByKey } from "../services/utils";
import Header from "../components/visual/header";
import Footer from "../components/visual/footer";

/**
 *
 * @param {*} htmlPart
 * @returns
 */
export const renderAsP = (htmlPart, className="") => {
  return <p className={className} dangerouslySetInnerHTML={{ __html: htmlPart }} />;
};

/**
 *
 * @param {*} htmlPart
 * @returns
 */
export const renderAsDiv = (htmlPart) => {
  return htmlPart && <div dangerouslySetInnerHTML={{ __html: htmlPart }} />;
};

function ChunkPage(props) {
  const [chunk] = useState(props.chunkKey);
  const [text, setText] = useState("");

  let navigate = useNavigate();

  const back = () => {
    navigate(-1);
  };

  useEffect(() => {
    document.title = props.title;
  }, [props.title]);

  useEffect(() => {
    const fetchDataChunk = async (chunk) => {
      try {
        const res = await getChunkByKey(chunk);
        setText(res.content);
      } catch (error) {
        console.log("Error fetching chunk");
      }
    };

    fetchDataChunk(chunk);
  }, [chunk]);

  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href={(props.path ? props.path : "")}
        />
        <meta name="description" content={props.title} />
      </Helmet>
      <Header />
      {/* 
      <PageHeader />
      <SectionImage title={props.title} />
      */}
      <div className="row">
        <div
          className="col-md-8 offset-md-2 col-xs-12 mt-4 text-page"
          style={{ minHeight: "75vh" }}
        >
          {text ? renderAsP(text) : <Skeleton active />}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ChunkPage;
